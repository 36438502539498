<template>
    <section class="payment-record">
        <div class="top-bar bg-white">
            <el-input v-model="formSearch.keyword" style="margin-right: 10px; width: 250px"
                      placeholder="请输入关键字搜索" size="small"/>
            <el-date-picker v-model="formSearch.startDate" type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
                            style="margin-right: 10px; width: 250px" size="small" placeholder="请选择开始日期"/>
            <el-date-picker v-model="formSearch.endDate" type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
                            style="margin-right: 10px; width: 250px" size="small" placeholder="请选择开始日期"/>
            <el-select v-model="value" placeholder="请选择收款账户" style="margin-right: 10px; width: 250px" size="small">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"/>
            </el-select>
            <el-button type="primary" @click="handleSearch" size="small">查询</el-button>
            <el-button @click="handleReset" size="small">重置</el-button>
            <el-button type="primary" size="small" @click="makeExport">导出数据</el-button>
        </div>

        <r-e-table class="bg-white" ref="orderTableRef" :dataRequest="getOrderTableData" :columns="tableColumn"
                   :query="formSearch" tooltip-effect="light" :height="760" show-summary :summary-method="getSummaries">
            <template slot="empty">
                <el-empty/>
            </template>
            <!--<el-table-column slot="toolbar" label="操作" width="100">
                <template slot-scope="{ row }">
                    <div class="table-tools">
                        <el-button type="danger" v-if="row.statusEnum==='paid'" size="mini" @click="refund(row)">退款</el-button>
                    </div>
                </template>
            </el-table-column>-->
        </r-e-table>
    </section>
</template>

<script>
    import {tableColumn} from "@/views/bill-management/batch-meter-reading/data";
    import {getOrderList, refund, makeExport} from "@/api/order";
    import {MessageSuccess, MessageError, MessageWarning} from "@custom/message";
    import {downloadByData} from "@/utils/hooks/download";
    import { timeFormat, numberFormat } from "@custom/index";
    export default {
        name: "batch-meter-reading",
        data() {
            return {
                tableColumn,
                tableData: [],
                formSearch: {},
                value: null,
                options: []
            };
        },
        async mounted() {},
        methods: {
            getOrderTableData(params) {
                return getOrderList(params);
            },
            handleSearch() {
                this.$refs.orderTableRef.pNumber = 1;
                this.$refs.orderTableRef.getTableData();
            },
            handleReset() {
                this.formSearch = {};
                Promise.resolve(this.formSearch).then(() => this.handleSearch());
            },
            refund(row) {
                let that = this;
                this.$confirm('此操作将退款,确定是否退款?', '提示', {confirmButtonText: '确定', cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let {transactionId, total} = row;
                    const loading = this.$loading({lock: true, text: 'Loading', spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.7)'});
                    refund({transactionId, amount: total}).then(res => {
                        console.log("add", res);
                        MessageSuccess('退款成功');
                        this.$refs.orderTableRef.getTableData();
                        loading.close();
                    }).finally(() => loading.close());
                }).catch(() => {});
            },
            makeExport() {
                const loading = this.$loading({lock: true, text: "正在导出请您稍等...", spinner: "el-icon-loading", background: "rgba(0, 0, 0, 0.7)"});
                let {keyword, startDate, endDate} = this.formSearch;
                let paramData = {keyword, startDate, endDate};
                makeExport(paramData).then(res => {
                    downloadByData({data: res, filename: `支付流水${timeFormat(new Date(),'yyyy-MM-dd-HH-mm-ss')}.xls`});
                    loading.close();
                }).finally(() => loading.close());
            },

            getSummaries(param) {
                const { columns, data } = param;
                const sums = [];
                columns.forEach((column, index) => {
                    if (index === 0) {
                        sums[index] = '合计';
                        return;
                    }
                    if (index !== 6 && index !== 7) {
                        sums[index] = '';
                        return;
                    }
                    const values = data.filter(item => item.statusEnum === 'paid').map(item => Number(item[column.property]));
                    if (!values.every(value => isNaN(value))) {
                        sums[index] = values.reduce((prev, curr) => {
                            const value = Number(curr);
                            if (!isNaN(value)) return prev + curr;
                            else return prev;
                        }, 0);
                        sums[index] = numberFormat(sums[index] / 100);
                    } else sums[index] = '';
                });
                return sums;
            }
        }
    };

</script>

<style lang="scss" scoped>
    .payment-record {
        padding: VH(15px) VW(15px);

        .top-bar {
            display: flex;
            margin: VH(10px) 0 VH(25px);
            padding: VH(15px) VW(15px);
            justify-content: flex-start;
        }

        /deep/ .r-e-table {
            .el-table .cell {
                white-space: nowrap;
                text-align: center;
            }
        }
    }
</style>